const routes = [
  {
    name: "open design",
    path: "/",
  },
  {
    name: "open code",
    path: "/code",
  },
]

export default routes
