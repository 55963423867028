import React from "react"
import styled from "@emotion/styled"
import mq from "../Utilities/MediaQuery.js"
import { css } from "@emotion/core"
import { Link } from "gatsby"

const StyledBlock = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  /* Once I get below 960px */
  width: 960px;
  margin-left: auto;
  margin-right: auto;
  /* font-family:'Courier New', Courier, monospace; */
  .post__image {
    width: 70%;
    margin-left: 25% !important;
    margin-right: 0 !important;
    margin-bottom: var(--space-medium);
    ${mq[1]} {
      width: 100%;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
  ${mq[2]} {
    /* implmement max width property so all other widths that are dependent on this value
        based off of a percentage as well. 
    */
    max-width: 100%;
  }
`

const PostBlock = props => {
  return <StyledBlock className="Post__Block">{props.children}</StyledBlock>
}

export default PostBlock
