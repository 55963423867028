import React from "react"
import styled from "@emotion/styled"
import mq from "../Utilities/MediaQuery.js"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import Routes from "../constants/routes.js"

var StyledCaption = styled.p`
  font-family: "IBM Plex Sans";
  font-size: 1.4rem;
  line-height: 1.5em;
  color: var(--accent-primary);
  width: 20%;
  margin-right: 5%;
  margin-bottom: 5%;
  ${mq[1]} {
    width: 100%;
    font-size: 1.35rem;
    display: none;
  }
`

const PostCaption = props => {
  return (
    <StyledCaption className="post__caption">{props.children}</StyledCaption>
  )
}

export default PostCaption
