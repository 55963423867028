import React from "react"
import styled from "@emotion/styled"
import mq from "../Utilities/MediaQuery.js"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import Routes from "../constants/routes.js"

const StyledContent = styled.p`
  line-height: 1.5em;
  width: 70%;
  &.post__body--nocaption {
    margin-left: 25%;
    ${mq[1]} {
      margin-left: 0;
    }
  }
  ${mq[1]} {
    width: 100%;
  }
`

const PostBody = props => {
  console.log("POST CONTENT", props)
  return (
    <StyledContent className={`post__body ${props.className || ""}`}>
      {props.children}
    </StyledContent>
  )
}

export default PostBody
